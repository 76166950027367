<template>
  <div class="management bj">
    <div class="title">
      <el-button
        type="primary"
        icon="el-icon-refresh"
        class="bjsearch"
        @click="Refresh"
      >
      </el-button>
      <el-button type="primary" @click="handelAdd">添加</el-button>
    </div>
    <div class="auto-table-flex">
      <el-table
        :data="tableData"
        height="100%"
        v-loading="loading"
        :border="true"
        :header-cell-style="{ color: '#333333', background: '#EFF6FF' }"
      >
        <el-table-column prop="fullname" align="center" label="用户名">
          <template slot-scope="scope">
            {{ $empty.empty(scope.row.username) }}
          </template>
        </el-table-column>
        <el-table-column prop="driver_phone" align="center" label="昵称">
          <template slot-scope="scope">
            {{ $empty.empty(scope.row.nickname) }}
          </template>
        </el-table-column>
        <el-table-column prop="id_card" align="center" label="角色">
          <template slot-scope="scope">
            {{ $empty.empty(scope.row.role_info.name) }}
          </template>
        </el-table-column>
        <el-table-column prop="id_card" align="center" label="状态">
          <template slot-scope="scope">
            <el-switch
              v-model="scope.row.status"
              :width="32"
              :active-value="1"
              active-text="启用"
              active-color="#409EFF"
              :inactive-value="0"
              inactive-text="禁用"
              inactive-color="#C0CCDA"
              @change="changeSwitch(scope.$index, scope.row, scope.row.status)"
            />
          </template>
        </el-table-column>
        <el-table-column label="操作" align="center" width="200px">
          <template slot-scope="scope">
            <el-button
              type="text"
              @click="handleUpdate(scope.$index, scope.row)"
              >修改
            </el-button>
            <!-- 禁用显示删除按钮 start -->
            <el-button
              v-if="scope.row.status == 0"
              type="text"
              @click="handleDelete(scope.$index, scope.row)"
              >删除
            </el-button>
            <!-- 禁用显示删除按钮 end -->
            <!-- 启用显示重置密码按钮 start -->
            <el-button
              v-if="scope.row.status == 1"
              type="text"
              @click="handlePassword(scope.$index, scope.row)"
              >重置密码
            </el-button>
            <!-- 启用显示重置密码按钮 start -->
          </template>
        </el-table-column>
      </el-table>
    </div>

    <!--  添加、编辑管理员弹框  -->
    <el-dialog
      :title="title"
      :visible.sync="centerDialogVisible"
      v-if="centerDialogVisible"
      width="40%"
      class="dialog"
      center
    >
      <div class="contents">
        <el-form
          :model="ruleForm"
          ref="ruleForm"
          label-width="100px"
          class="demo-ruleForm"
        >
          <el-form-item label="账号" prop="username">
            <el-input
              v-model="ruleForm.username"
              placeholder="请输入账号"
              :disabled="!showid"
              style="width: 302px"
            ></el-input>
          </el-form-item>
          <el-form-item label="密码" prop="password" v-if="showid">
            <el-input
              type="password"
              show-password
              v-model="ruleForm.password"
              placeholder="请输入密码"
              style="width: 302px"
            ></el-input>
          </el-form-item>
          <el-form-item label="昵称" prop="nickname">
            <el-input
              v-model="ruleForm.nickname"
              placeholder="请输入昵称"
              style="width: 302px"
            ></el-input>
          </el-form-item>
          <el-form-item label="状态" prop="state">
            <el-radio-group v-model="ruleForm.status">
              <el-radio :label="1">正常</el-radio>
              <el-radio :label="0">停用</el-radio>
            </el-radio-group>
          </el-form-item>
          <el-form-item label="所属角色" prop="role_id">
            <el-select
              v-model="ruleForm.role_id"
              placeholder="请选择角色"
              style="width: 302px"
            >
              <el-option
                v-for="item of roleList"
                :key="item.id"
                :label="item.name"
                :value="item.id"
              />
            </el-select>
          </el-form-item>
        </el-form>
      </div>
      <span slot="footer" class="dialog-footer">
        <el-button @click="centerDialogVisible = false">取 消</el-button>
        <el-button type="primary" @click="add">确认</el-button>
      </span>
    </el-dialog>

    <div class="fy">
      <el-pagination
        type="primary"
        background
        @size-change="handleSizeChange"
        @current-change="handleCurrentChange"
        :current-page="form.page"
        :page-sizes="[10, 20, 50, 100]"
        :page-size="10"
        layout="total, sizes, prev, pager, next, jumper"
        :total="total"
      >
      </el-pagination>
    </div>
    <!-- 登录密码已重置 弹窗 start -->
    <el-dialog
      title="登录密码已重置"
      :visible.sync="newPassword"
      :close-on-click-modal="false"
      :close-on-press-escape="false"
      @close="onHandleClosePassword"
      width="30%"
      class="dialog"
      center
    >
      <div class="contents">
        <div class="mb-10">登录账户：{{ changeItem.username }}</div>
        <div class="mb-25 d-flex a-center">
          重置密码：{{ newPasswordText }}
          <div style="margin-left: 10px">
            <el-button type="primary" @click="onHandleCopy">复制</el-button>
          </div>
        </div>
      </div>
      <span slot="footer" class="dialog-footer">
        <el-button @click="onHandleClosePassword">取 消</el-button>
        <el-button type="primary" @click="onHandleClosePassword">
          关闭
        </el-button>
      </span>
    </el-dialog>
    <!-- 登录密码已重置 弹窗 end -->
  </div>
</template>
<script>
// import { Message } from "element-ui";
// import router from "@/router";

export default {
  name: "adminment",
  components: {},
  data() {
    return {
      loading: false,
      form: {
        page: 1,
        pageSize: 10,
      },
      tableData: [],
      roleList: [],
      total: 0,
      centerDialogVisible: false,
      showid: false,
      title: "",
      manageIsdentity: "",
      ruleForm: {
        business_user_id: 0,
        nickname: "",
        username: "",
        password: "",
        role_id: "",
        status: "",
      },
      newPassword: false,
      newPasswordText: "",
      changeItem: {},
    };
  },
  created() {
    this.manageIsdentity = sessionStorage.getItem("manageIsdentity");
    this.getMemberList();
    this.getRoleList();
  },
  methods: {
    // 删除用户
    handleDelete(index, row) {
      this.$confirm("确定要删除该用户吗？", "提示", {
        center: true,
        confirmButtonText: "确 定",
        cancelButtonText: "取 消",
        customClass: "jn-confirm-message-style",
        iconClass: "el-icon-warning",
      }).then(async () => {
        try {
          const res = await this.$api.general.generalUserDelete({
            business_user_id: row.id,
          });

          this.$message({
            message: "删除成功",
            type: "success",
          });
          this.Refresh();
          console.log(res, "res==");
        } catch (error) {
          console.log(error, "generalUserDelete");
        }
      });
    },
    // 重置密码
    handlePassword(index, row) {
      this.$confirm("确定要重置密码吗？", "提示", {
        center: true,
        confirmButtonText: "确 定",
        cancelButtonText: "取 消",
        customClass: "jn-confirm-message-style",
        iconClass: "el-icon-warning",
      }).then(async () => {
        this.changeItem = row;
        try {
          const res = await this.$api.general.generalUserResetPassword({
            business_user_id: row.id,
          });

          this.$message({
            message: "重置成功",
            type: "success",
          });
          this.newPassword = true;
          this.newPasswordText = res.data.password;
          console.log(res, "res==");
        } catch (error) {
          console.log(error, "generalUserDelete");
        }
      });
    },
    /**
     * 复制 重置后的密码
     */
    async onHandleCopy() {
      try {
        const input = document.createElement("input");
        input.value = this.newPasswordText;
        document.body.appendChild(input);
        input.select();
        if (document.execCommand("Copy")) {
          this.$message.success("复制成功");
        } else {
          this.$message.error("复制失败");
        }
        document.body.removeChild(input);
      } catch (err) {
        this.$message.error("error");
      }
    },
    /**
     * 关闭重置密码弹窗
     */
    onHandleClosePassword() {
      this.newPassword = false;
    },
    // 获取列表数据
    getMemberList() {
      this.loading = true;
      // 总控中心
      if (this.manageIsdentity == "general") {
        console.log("🆒 getMemberList", this.form);
        this.$api.general.generalUserList(this.form).then((res) => {
          this.tableData = res.data.data;
          this.total = res.data.total;
        });
      } // 集配中心
      else if (this.manageIsdentity == "logistics") {
        this.$api.logistics.getLogisticsUser(this.form).then((res) => {
          this.tableData = res.data.data;
          this.total = res.data.total;
        });
      } // 自提点中心
      else if (this.manageIsdentity == "delivery") {
        this.$api.delivery.getDeliveryUser(this.form).then((res) => {
          this.tableData = res.data.data;
          this.total = res.data.total;
        });
      }
      // 城市仓
      else if (this.manageIsdentity == "tdc") {
        this.$api.tdc.getDeliveryUser(this.form).then((res) => {
          this.tableData = res.data.data;
          this.total = res.data.total;
        });
      }
      this.loading = false;
    },
    // 获取角色列表
    getRoleList() {
      // 总控中心
      if (this.manageIsdentity == "general") {
        this.$api.general
          .generalRoleList({ page: 1, pageSize: 200 })
          .then((res) => {
            this.roleList = res.data.data;
          });
      } // 集配中心
      else if (this.manageIsdentity == "logistics") {
        this.$api.logistics
          .getRoleList({ page: 1, pageSize: 200 })
          .then((res) => {
            this.roleList = res.data.data;
          });
      } // 自提点中心
      else if (this.manageIsdentity == "delivery") {
        this.$api.delivery
          .getRoleList({ page: 1, pageSize: 200 })
          .then((res) => {
            this.roleList = res.data.data;
          });
      } // 城市仓
      else if (this.manageIsdentity == "tdc") {
        this.$api.tdc.getRoleList({ page: 1, pageSize: 200 }).then((res) => {
          this.roleList = res.data.data;
        });
      }
    },
    // 刷新
    Refresh() {
      this.getMemberList();
    },
    // 分页选择显示数量
    handleSizeChange(val) {
      this.form.page = 1;
      this.form.pageSize = val;
      this.getMemberList();
    },
    // 分页选择当前页
    handleCurrentChange(val) {
      this.form.page = val;
      this.getMemberList();
    },
    // 添加
    handelAdd() {
      this.ruleForm = {
        business_user_id: 0,
        nickname: "",
        username: "",
        password: "",
        role_id: "",
        status: "",
      };
      this.centerDialogVisible = true;
      this.showid = true;
      this.title = "添加管理员";
    },
    add() {
      if (this.showid) {
        this.insert();
      } else {
        this.update();
      }
    },
    // 添加用户
    insert() {
      // 总控中心
      if (this.manageIsdentity == "general") {
        this.$api.general.addGeneralUser(this.ruleForm).then((res) => {
          if (res.code == 20000) {
            this.$message({
              message: "添加成功",
              type: "success",
            });
            setTimeout(() => {
              this.centerDialogVisible = false;
              this.Refresh();
            }, 500);
          }
        });
      } // 集配中心
      else if (this.manageIsdentity == "logistics") {
        this.$api.logistics.saveLogisticsUser(this.ruleForm).then((res) => {
          if (res.code == 20000) {
            this.$message({
              message: "添加成功",
              type: "success",
            });
            setTimeout(() => {
              this.centerDialogVisible = false;
              this.Refresh();
            }, 500);
          }
        });
      } // 自提点中心
      else if (this.manageIsdentity == "delivery") {
        this.$api.delivery.saveDeliveryUser(this.ruleForm).then((res) => {
          if (res.code == 20000) {
            this.$message({
              message: "添加成功",
              type: "success",
            });
            setTimeout(() => {
              this.centerDialogVisible = false;
              this.Refresh();
            }, 500);
          }
        });
      }
      // 城市仓
      else if (this.manageIsdentity == "tdc") {
        this.$api.tdc.saveDeliveryUser(this.ruleForm).then((res) => {
          if (res.code == 20000) {
            this.$message({
              message: "添加成功",
              type: "success",
            });
            setTimeout(() => {
              this.centerDialogVisible = false;
              this.Refresh();
            }, 500);
          }
        });
      }
    },
    // 修改
    handleUpdate(index, row) {
      this.ruleForm = {
        business_user_id: row.id,
        nickname: row.nickname,
        username: row.username,
        password: row.password,
        role_id: row.role_id,
        status: row.status,
      };
      this.centerDialogVisible = true;
      this.showid = false;
      this.title = "编辑管理员";
    },
    update() {
      // 总控中心
      if (this.manageIsdentity == "general") {
        this.$api.general.updateGeneralUser(this.ruleForm).then((res) => {
          if (res.code == 20000) {
            this.$message({
              message: "修改成功",
              type: "success",
            });
            setTimeout(() => {
              this.centerDialogVisible = false;
              this.Refresh();
            }, 500);
          }
        });
      } // 集配中心
      else if (this.manageIsdentity == "logistics") {
        this.$api.logistics.saveLogisticsUser(this.ruleForm).then((res) => {
          if (res.code == 20000) {
            this.$message({
              message: "修改成功",
              type: "success",
            });
            setTimeout(() => {
              this.centerDialogVisible = false;
              this.Refresh();
            }, 500);
          }
        });
      } // 自提点中心
      else if (this.manageIsdentity == "delivery") {
        this.$api.delivery.saveDeliveryUser(this.ruleForm).then((res) => {
          if (res.code == 20000) {
            this.$message({
              message: "修改成功",
              type: "success",
            });
            setTimeout(() => {
              this.centerDialogVisible = false;
              this.Refresh();
            }, 500);
          }
        });
      }
      // 城市仓
      else if (this.manageIsdentity == "tdc") {
        this.$api.tdc.saveDeliveryUser(this.ruleForm).then((res) => {
          if (res.code == 20000) {
            this.$message({
              message: "修改成功",
              type: "success",
            });
            setTimeout(() => {
              this.centerDialogVisible = false;
              this.Refresh();
            }, 500);
          }
        });
      }
    },
    // 修改状态
    changeSwitch(index, row, state) {
      console.log(state, "state");

      // 总控中心
      if (this.manageIsdentity == "general") {
        this.$api.general
          .updateGeneralUserStatus({ business_user_id: row.id, status: state })
          .then((res) => {
            if (res.code == 20000) {
              this.$message({
                message: state == 1 ? "该管理员已启用！" : "该管理员已禁用！",
                type: "success",
              });
            } else {
              this.$message({ message: "修改管理员状态失败！", type: "error" });
              this.Refresh();
            }
          }).catch(err=>{
            this.$set(this.tableData[index],"status",state==1?0:1)
            
          });
        this.loading = false;
      } // 集配中心
      else if (this.manageIsdentity == "logistics") {
        this.$api.logistics
          .updateLogisticsStatus({ business_user_id: row.id, status: state })
          .then((res) => {
            if (res.code == 20000) {
              this.$message({
                message: state == 1 ? "该管理员已启用！" : "该管理员已禁用！",
                type: "success",
              });
            } else {
              this.$message({ message: "修改管理员状态失败！", type: "error" });
              this.Refresh();
            }
          }).catch(err=>{
           this.$set(this.tableData[index],"status",state==1?0:1)
           
          });
        this.loading = false;
      } // 自提点中心
      else if (this.manageIsdentity == "delivery") {
        this.$api.delivery
          .updateDeliveryUserStatus({ business_user_id: row.id, status: state })
          .then((res) => {
            if (res.code == 20000) {
              this.$message({
                message: state == 1 ? "该管理员已启用！" : "该管理员已禁用！",
                type: "success",
              });
            } else {
              this.$message({ message: "修改管理员状态失败！", type: "error" });
              this.Refresh();
            }
          }).catch(err=>{
           this.$set(this.tableData[index],"status",state==1?0:1)
           
          });
        this.loading = false;
      }
      // 城市仓
      else if (this.manageIsdentity == "tdc") {
        this.$api.tdc
          .updateDeliveryUserStatus({ business_user_id: row.id, status: state })
          .then((res) => {
            if (res.code == 20000) {
              this.$message({
                message: state == 1 ? "该管理员已启用！" : "该管理员已禁用！",
                type: "success",
              });
            } else {
              this.$message({ message: "修改管理员状态失败！", type: "error" });
              this.Refresh();
            }
          }).catch(err=>{
           this.$set(this.tableData[index],"status",state==1?0:1)
           
console.log(this.tableData,'111111111111111111111')

          });
        this.loading = false;
      }
      

    },
  },
};
</script>
<style lang="scss">
.management {
  display: flex;
  flex-direction: column;
  flex-wrap: nowrap;
  height: 100%;
  box-sizing: border-box;
  padding: 10px;

  .title {
    background: #ffffff;
    padding: 13px 15px;
    box-sizing: border-box;
    display: flex;

    .seach {
      flex: 1;
      display: flex;
      justify-content: flex-end;

      .inputs {
        width: 207px;
        margin-right: 8px;
      }
    }

    .bjsearch {
      background: #333333;
      border-color: #333333;
    }
  }

  .auto-table-flex {
    flex-grow: 1;
    overflow-y: hidden;
  }

  .contents {
    padding-left: 55px;
    padding-right: 55px;
    padding-top: 23px;

    .titlname {
      font-size: 17px;
      color: #349ee6;
      margin-bottom: 25px;
    }

    .imgs {
      display: flex;

      .imglist {
        width: 165px;
        height: 123px;
        border: 1px dashed #a4adc5;
        padding: 14px 8px;
        text-align: center;
        border-radius: 7px;
        box-sizing: border-box;
        margin-right: 16px;

        .div {
          margin-top: 20px;
        }
      }
    }

    .disply {
      display: flex;
      margin-bottom: 22px;
    }

    .mr {
      margin-right: 31px;
    }

    .iteminput {
      display: flex;
      align-items: center;

      .wen {
        width: 100px;
        margin-right: 24px;
        color: #000;
        font-size: 14px;
        text-align: right;
      }

      .el-input {
        width: 302px;
      }
    }
  }

  .fy {
    box-sizing: border-box;
    padding: 10px 20px;
  }
}

.colors {
  color: #fa884c;
}
</style>
