var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "management bj" },
    [
      _c(
        "div",
        { staticClass: "title" },
        [
          _c("el-button", {
            staticClass: "bjsearch",
            attrs: { type: "primary", icon: "el-icon-refresh" },
            on: { click: _vm.Refresh },
          }),
          _c(
            "el-button",
            { attrs: { type: "primary" }, on: { click: _vm.handelAdd } },
            [_vm._v("添加")]
          ),
        ],
        1
      ),
      _c(
        "div",
        { staticClass: "auto-table-flex" },
        [
          _c(
            "el-table",
            {
              directives: [
                {
                  name: "loading",
                  rawName: "v-loading",
                  value: _vm.loading,
                  expression: "loading",
                },
              ],
              attrs: {
                data: _vm.tableData,
                height: "100%",
                border: true,
                "header-cell-style": {
                  color: "#333333",
                  background: "#EFF6FF",
                },
              },
            },
            [
              _c("el-table-column", {
                attrs: { prop: "fullname", align: "center", label: "用户名" },
                scopedSlots: _vm._u([
                  {
                    key: "default",
                    fn: function (scope) {
                      return [
                        _vm._v(
                          " " +
                            _vm._s(_vm.$empty.empty(scope.row.username)) +
                            " "
                        ),
                      ]
                    },
                  },
                ]),
              }),
              _c("el-table-column", {
                attrs: { prop: "driver_phone", align: "center", label: "昵称" },
                scopedSlots: _vm._u([
                  {
                    key: "default",
                    fn: function (scope) {
                      return [
                        _vm._v(
                          " " +
                            _vm._s(_vm.$empty.empty(scope.row.nickname)) +
                            " "
                        ),
                      ]
                    },
                  },
                ]),
              }),
              _c("el-table-column", {
                attrs: { prop: "id_card", align: "center", label: "角色" },
                scopedSlots: _vm._u([
                  {
                    key: "default",
                    fn: function (scope) {
                      return [
                        _vm._v(
                          " " +
                            _vm._s(_vm.$empty.empty(scope.row.role_info.name)) +
                            " "
                        ),
                      ]
                    },
                  },
                ]),
              }),
              _c("el-table-column", {
                attrs: { prop: "id_card", align: "center", label: "状态" },
                scopedSlots: _vm._u([
                  {
                    key: "default",
                    fn: function (scope) {
                      return [
                        _c("el-switch", {
                          attrs: {
                            width: 32,
                            "active-value": 1,
                            "active-text": "启用",
                            "active-color": "#409EFF",
                            "inactive-value": 0,
                            "inactive-text": "禁用",
                            "inactive-color": "#C0CCDA",
                          },
                          on: {
                            change: function ($event) {
                              return _vm.changeSwitch(
                                scope.$index,
                                scope.row,
                                scope.row.status
                              )
                            },
                          },
                          model: {
                            value: scope.row.status,
                            callback: function ($$v) {
                              _vm.$set(scope.row, "status", $$v)
                            },
                            expression: "scope.row.status",
                          },
                        }),
                      ]
                    },
                  },
                ]),
              }),
              _c("el-table-column", {
                attrs: { label: "操作", align: "center", width: "200px" },
                scopedSlots: _vm._u([
                  {
                    key: "default",
                    fn: function (scope) {
                      return [
                        _c(
                          "el-button",
                          {
                            attrs: { type: "text" },
                            on: {
                              click: function ($event) {
                                return _vm.handleUpdate(scope.$index, scope.row)
                              },
                            },
                          },
                          [_vm._v("修改 ")]
                        ),
                        scope.row.status == 0
                          ? _c(
                              "el-button",
                              {
                                attrs: { type: "text" },
                                on: {
                                  click: function ($event) {
                                    return _vm.handleDelete(
                                      scope.$index,
                                      scope.row
                                    )
                                  },
                                },
                              },
                              [_vm._v("删除 ")]
                            )
                          : _vm._e(),
                        scope.row.status == 1
                          ? _c(
                              "el-button",
                              {
                                attrs: { type: "text" },
                                on: {
                                  click: function ($event) {
                                    return _vm.handlePassword(
                                      scope.$index,
                                      scope.row
                                    )
                                  },
                                },
                              },
                              [_vm._v("重置密码 ")]
                            )
                          : _vm._e(),
                      ]
                    },
                  },
                ]),
              }),
            ],
            1
          ),
        ],
        1
      ),
      _vm.centerDialogVisible
        ? _c(
            "el-dialog",
            {
              staticClass: "dialog",
              attrs: {
                title: _vm.title,
                visible: _vm.centerDialogVisible,
                width: "40%",
                center: "",
              },
              on: {
                "update:visible": function ($event) {
                  _vm.centerDialogVisible = $event
                },
              },
            },
            [
              _c(
                "div",
                { staticClass: "contents" },
                [
                  _c(
                    "el-form",
                    {
                      ref: "ruleForm",
                      staticClass: "demo-ruleForm",
                      attrs: { model: _vm.ruleForm, "label-width": "100px" },
                    },
                    [
                      _c(
                        "el-form-item",
                        { attrs: { label: "账号", prop: "username" } },
                        [
                          _c("el-input", {
                            staticStyle: { width: "302px" },
                            attrs: {
                              placeholder: "请输入账号",
                              disabled: !_vm.showid,
                            },
                            model: {
                              value: _vm.ruleForm.username,
                              callback: function ($$v) {
                                _vm.$set(_vm.ruleForm, "username", $$v)
                              },
                              expression: "ruleForm.username",
                            },
                          }),
                        ],
                        1
                      ),
                      _vm.showid
                        ? _c(
                            "el-form-item",
                            { attrs: { label: "密码", prop: "password" } },
                            [
                              _c("el-input", {
                                staticStyle: { width: "302px" },
                                attrs: {
                                  type: "password",
                                  "show-password": "",
                                  placeholder: "请输入密码",
                                },
                                model: {
                                  value: _vm.ruleForm.password,
                                  callback: function ($$v) {
                                    _vm.$set(_vm.ruleForm, "password", $$v)
                                  },
                                  expression: "ruleForm.password",
                                },
                              }),
                            ],
                            1
                          )
                        : _vm._e(),
                      _c(
                        "el-form-item",
                        { attrs: { label: "昵称", prop: "nickname" } },
                        [
                          _c("el-input", {
                            staticStyle: { width: "302px" },
                            attrs: { placeholder: "请输入昵称" },
                            model: {
                              value: _vm.ruleForm.nickname,
                              callback: function ($$v) {
                                _vm.$set(_vm.ruleForm, "nickname", $$v)
                              },
                              expression: "ruleForm.nickname",
                            },
                          }),
                        ],
                        1
                      ),
                      _c(
                        "el-form-item",
                        { attrs: { label: "状态", prop: "state" } },
                        [
                          _c(
                            "el-radio-group",
                            {
                              model: {
                                value: _vm.ruleForm.status,
                                callback: function ($$v) {
                                  _vm.$set(_vm.ruleForm, "status", $$v)
                                },
                                expression: "ruleForm.status",
                              },
                            },
                            [
                              _c("el-radio", { attrs: { label: 1 } }, [
                                _vm._v("正常"),
                              ]),
                              _c("el-radio", { attrs: { label: 0 } }, [
                                _vm._v("停用"),
                              ]),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                      _c(
                        "el-form-item",
                        { attrs: { label: "所属角色", prop: "role_id" } },
                        [
                          _c(
                            "el-select",
                            {
                              staticStyle: { width: "302px" },
                              attrs: { placeholder: "请选择角色" },
                              model: {
                                value: _vm.ruleForm.role_id,
                                callback: function ($$v) {
                                  _vm.$set(_vm.ruleForm, "role_id", $$v)
                                },
                                expression: "ruleForm.role_id",
                              },
                            },
                            _vm._l(_vm.roleList, function (item) {
                              return _c("el-option", {
                                key: item.id,
                                attrs: { label: item.name, value: item.id },
                              })
                            }),
                            1
                          ),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                ],
                1
              ),
              _c(
                "span",
                {
                  staticClass: "dialog-footer",
                  attrs: { slot: "footer" },
                  slot: "footer",
                },
                [
                  _c(
                    "el-button",
                    {
                      on: {
                        click: function ($event) {
                          _vm.centerDialogVisible = false
                        },
                      },
                    },
                    [_vm._v("取 消")]
                  ),
                  _c(
                    "el-button",
                    { attrs: { type: "primary" }, on: { click: _vm.add } },
                    [_vm._v("确认")]
                  ),
                ],
                1
              ),
            ]
          )
        : _vm._e(),
      _c(
        "div",
        { staticClass: "fy" },
        [
          _c("el-pagination", {
            attrs: {
              type: "primary",
              background: "",
              "current-page": _vm.form.page,
              "page-sizes": [10, 20, 50, 100],
              "page-size": 10,
              layout: "total, sizes, prev, pager, next, jumper",
              total: _vm.total,
            },
            on: {
              "size-change": _vm.handleSizeChange,
              "current-change": _vm.handleCurrentChange,
            },
          }),
        ],
        1
      ),
      _c(
        "el-dialog",
        {
          staticClass: "dialog",
          attrs: {
            title: "登录密码已重置",
            visible: _vm.newPassword,
            "close-on-click-modal": false,
            "close-on-press-escape": false,
            width: "30%",
            center: "",
          },
          on: {
            "update:visible": function ($event) {
              _vm.newPassword = $event
            },
            close: _vm.onHandleClosePassword,
          },
        },
        [
          _c("div", { staticClass: "contents" }, [
            _c("div", { staticClass: "mb-10" }, [
              _vm._v("登录账户：" + _vm._s(_vm.changeItem.username)),
            ]),
            _c("div", { staticClass: "mb-25 d-flex a-center" }, [
              _vm._v(" 重置密码：" + _vm._s(_vm.newPasswordText) + " "),
              _c(
                "div",
                { staticStyle: { "margin-left": "10px" } },
                [
                  _c(
                    "el-button",
                    {
                      attrs: { type: "primary" },
                      on: { click: _vm.onHandleCopy },
                    },
                    [_vm._v("复制")]
                  ),
                ],
                1
              ),
            ]),
          ]),
          _c(
            "span",
            {
              staticClass: "dialog-footer",
              attrs: { slot: "footer" },
              slot: "footer",
            },
            [
              _c("el-button", { on: { click: _vm.onHandleClosePassword } }, [
                _vm._v("取 消"),
              ]),
              _c(
                "el-button",
                {
                  attrs: { type: "primary" },
                  on: { click: _vm.onHandleClosePassword },
                },
                [_vm._v(" 关闭 ")]
              ),
            ],
            1
          ),
        ]
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }